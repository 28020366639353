var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slick-carousel-slider"},[_c('VueSlickCarousel',_vm._b({staticClass:"white-arrows",scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-left"})])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]}}])},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.content),function(ref,index){
var title = ref.title;
var ctas = ref.ctas;
var mobileImage = ref.mobileImage;
var desktopImage = ref.desktopImage;
var className = ref.className;
return _c('div',{key:(title + "-" + index),staticClass:"slick-item",class:className},[_c('img',{staticClass:"d-inline d-md-none",attrs:{"sizes":"100vw","srcset":("\n                    " + _vm.image_prefix + "c_fill,w_320,h_533/f_auto,q_70/" + (mobileImage.src) + " 320w,\n                    " + _vm.image_prefix + "c_fill,w_640,h_1066/f_auto,q_70/" + (mobileImage.src) + " 640w,\n                    " + _vm.image_prefix + "c_fill,w_768,h_1279/f_auto,q_70/" + (mobileImage.src) + " 768w,\n                    " + _vm.image_prefix + "c_fill,w_1024,h_1706/f_auto,q_70/" + (mobileImage.src) + " 1024w\n                "),"src":(_vm.image_prefix + "c_fill,w_640,h_1066/f_auto,q_70/" + (mobileImage.src)),"alt":mobileImage.alt,"loading":"lazy","width":"640","height":"1066"}}),_vm._v(" "),_c('img',{staticClass:"d-none d-md-inline",attrs:{"sizes":"100vw","srcset":("\n                    " + _vm.image_prefix + "c_fill,w_512,h_342/f_auto,q_70/" + (desktopImage.src) + " 512w,\n                    " + _vm.image_prefix + "c_fill,w_768,h_512/f_auto,q_70/" + (desktopImage.src) + " 768w,\n                    " + _vm.image_prefix + "c_fill,w_1024,h_683/f_auto,q_70/" + (desktopImage.src) + " 1024w,\n                    " + _vm.image_prefix + "c_fill,w_1280,h_853/f_auto,q_70/" + (desktopImage.src) + " 1280w,\n                    " + _vm.image_prefix + "c_fill,w_2500,h_1666/f_auto,q_70/" + (desktopImage.src) + " 2500w\n                "),"src":(_vm.image_prefix + "c_fill,w_2500,h_1666/f_auto,q_70/" + (desktopImage.src)),"alt":desktopImage.alt,"loading":"lazy","width":"2500","height":"1666"}}),_vm._v(" "),_c('div',{staticClass:"copy max-width-wide mx-auto"},[_c('div',{staticClass:"copy-inside"},[_c('h2',{domProps:{"innerHTML":_vm._s(title)}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-wrap justify-content-center gap-3"},_vm._l((ctas),function(ref,ctasIndex){
                var title = ref.title;
                var url = ref.url;
return _c('div',{key:(title + "-" + index + "-" + ctasIndex)},[(title && url)?_c('nuxt-link',{staticClass:"btn semi-bold small border-radius round",class:className,attrs:{"to":url}},[_vm._v("\n                                "+_vm._s(title)+"\n                            ")]):_vm._e()],1)}),0)])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }