import { render, staticRenderFns } from "./VideoCopySplit.vue?vue&type=template&id=a1a5d82c&scoped=true&"
import script from "./VideoCopySplit.vue?vue&type=script&lang=ts&"
export * from "./VideoCopySplit.vue?vue&type=script&lang=ts&"
import style0 from "./VideoCopySplit.vue?vue&type=style&index=0&id=a1a5d82c&prod&lang=scss&scoped=true&"
import style1 from "./VideoCopySplit.vue?vue&type=style&index=1&id=a1a5d82c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1a5d82c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Video: require('/var/www/components/Video.vue').default})
