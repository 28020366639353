












































































  import {
      defineComponent,
      // ref,
      // useContext,
      // onMounted,
      // useFetch,
  } from '@nuxtjs/composition-api';

  import LazyHydrate from 'vue-lazy-hydration';

  export default defineComponent({
      name: 'TitleDescription',
      components: {
          LazyHydrate
      },
      setup() {
          
      },
      head() {
          return {
              
          }
      },
      data() {

          var titleTag = 'h2'

          if (this.data.titleTag) {
              titleTag = this.data.titleTag
          }

          var title_description_collapse_visible = false

          return {
              titleTag,
              image_prefix: '',
              title_description_collapse_visible
          }
      },
      created() {
          if (this.margin) {
              this.data.margin = this.margin
          }

          this.image_prefix = this.$config.baseImageUrl
      },
      props: {
          padding: {
              type: String,
              default: ''
          },
          margin: {
              type: String,
              default: null
          },
          background: {
              type: String,
              default: 'background-white'
          },
          titleBorder: {
              type: Boolean,
              default: false
          },
          descriptionClass: {
              type: String,
              default: ''
          },
          data: {
              type: Object,
              default() {
                  return {
                      title: String,
                      description: String,
                      titleTag: {
                          type: String,
                          default: 'h2'                
                      },
                      topBanner: {
                          type: Object,
                          default() {
                              return {
                                  title_1: String,
                                  title_2: String,
                                  mobileImage: {
                                      type: Object,
                                      default() {
                                          return {
                                              src: String,
                                              width: Number,
                                              height: Number,
                                              alt: String
                                          }
                                      }
                                  },
                                  desktopImage: {
                                      type: Object,
                                      default() {
                                          return {
                                              src: String,
                                              width: Number,
                                              height: Number,
                                              alt: String
                                          }
                                      }
                                  }
                              }
                          }
                      },
                  }
              }
          },
          matchOriginalCategory: {
              type: Boolean,
              default: true
          },
          limitCopyLength: {
              type: Boolean,
              default: false
          }
      },
      methods: {
          getInitialParagraph(description) {
              if (description) {
                  return description.split('</p>')[0] + '</p>'
              }
          },
          getRemainingContent(description) {
              if (description) {
                  var paragraphSections = description.split('</p>')
                  paragraphSections.splice(0, 1);
                  paragraphSections = paragraphSections.join('</p>')
                  return paragraphSections
              }
          }
      },
      // computed: {
      //     getInitialParagraph: function() {
      //         if (this.data.description) {
      //             return this.data.description.split('</p>')[0] + '</p>'
      //         }
      //     },
      //     getRemainingContent: function() {
      //         if (this.data.description) {
      //             var paragraphSections = this.data.description.split('</p>')
      //             paragraphSections.splice(0, 1);
      //             paragraphSections = paragraphSections.join('</p>')
      //             return paragraphSections
      //         }
      //     }
      // }
      // watch: {
      //     data: {
      //         handler: function(newValue) {
      //             console.log(newValue);
      //             console.log('-----TEST-----');
      //         },
      //         immediate: true
      //     }
      // }
});
