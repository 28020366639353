






















































import SvgImage from '~/components/General/SvgImage.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import {
    SfLink
    // SfButton,
    // SfIconPackage,
    // SfIconWarehouse,
    // SfIconPublishedWithChanges
} from '@storefront-ui/vue';
import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default {
    name: 'IconBar',
    components: {
        // SfLink,
        // SvgImage,
        VueSlickCarousel
    },
    props: {
        content: {
            type: Object,
            default() {
                return {
                    title: String,
                    description: String,
                    image: {
                        type: Object,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            cardDetails: [
                {
                    icon: 'fa-solid fa-circle-check',
                    title: 'Price',
                    description: 'Unbeatable prices guaranteed'
                },
                {
                    icon: 'fa-solid fa-stars',
                    title: 'Quality',
                    description: 'Crafting excellence for over 20 years'
                },
                {
                    icon: 'fa-solid fa-headset',
                    title: 'Support',
                    description: 'Friendly support guiding you at every step'
                },
                {
                    icon: 'fa-solid fa-truck-ramp-box',
                    title: 'Direct',
                    description: 'From our factory to your home'
                },
                {
                    icon: 'fa-solid fa-award',
                    title: 'Trust',
                    description: 'Top rated family business with excellent reviews'
                }
            ],
            settings: {
                arrows: false,
                dots: false,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: false,
                speed: 500,
                autoplaySpeed: 5000,
                cssEase: "ease-in-out",
                centerMode: true,
                centerPadding: '1px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: '40px',
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: '40px',
                            infinite: false
                        }
                    }
                ]
            },
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    methods: {
        
    }
}
