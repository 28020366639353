










































































import VueSlickCarousel from 'vue-slick-carousel'
import { SfLink } from '@storefront-ui/vue';

import { useContent } from '~/composables';

import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

// import axios from "axios";

// import {
//     WntrTrustPilot
// } from '~/modules/GraphQL/types';

export default defineComponent({
    name: 'TrustPilot',
    components: {
        VueSlickCarousel
    },
    setup() {

        const { 
            getTrustPilotData
        } = useContent();

        // const wntrTrustPilotRef = ref<WntrTrustPilot | null>(null);

        var wntrTrustPilotRef = getTrustPilotData();
        // console.log("*** getTrustPilotData() ***");
        // console.log(wntrTrustPilot);
    
        return {
            wntrTrustPilotRef
        }

    },
    async fetch() {
        this.wntrTrustPilot = await this.wntrTrustPilotRef

        this.processWntrTrustPilot()
    },
    data() {
        return {
            wntrTrustPilot: null,

            settings: {
                dots: false,
                // dotsClass: "slick-dots custom-dot-class",
                // edgeFriction: 0.35,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 5000,
                // cssEase: "ease",
                // prevArrow: '<i class="fa-solid fa-chevron-left"></i>',
                // nextArrow: '<i class="fa-solid fa-chevron-right"></i>',
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            },

            overall_stars_rounded: 0
        }
    },
    props: {
        includeIntro: {
            type: Boolean,
            default: true
        },
        includeFeatured: {
            type: Boolean,
            default: true
        },
        includeMiniReviews: {
            type: Boolean,
            default: true
        },
        content: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        getReviewDate(iso_date) {
            var date = new Date(iso_date)
            const day = date.toLocaleString('default', { day: 'numeric' })
            const month = date.toLocaleString('default', { month: 'long' })
            const year = date.toLocaleString('default', { year: 'numeric' })
            // return iso_date + ' test';
            return day + ' ' + month + ' ' + year
        },
        getRoundedScore(stars) {
            return  Math.round(stars).toFixed(1).split('.').join("")
        },
        processWntrTrustPilot() {
            // this.overall_score_rounded = Math.round(newValue.overall_score).toFixed(1).split('.').join("")
            this.overall_stars_rounded = this.wntrTrustPilot.overall_stars.split('.').join("")
        }
    }
})
