





















































































































































































































import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
    name: 'FullImageCardBanner',
    components: {
        SfButton
    },
    props: {
        data: {
            type: Object,
            default () {
                return {
                    title: String,
                    description: String,
                    cards: {
                        type: Array,
                        default() {
                            return {
                                title: String,
                                description: String,
                                cta_title: String,
                                cta_url: {
                                    type: Object,
                                    default() {
                                        return {
                                            name: String
                                        }
                                    }
                                },
                                image: {
                                    type: Object,
                                    default() {
                                        return {
                                            src: String,
                                            width: Number,
                                            height: Number,
                                            alt: String
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            breakpointClass: null,
            showByIndex: null,
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    mounted() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            this.breakpointClass = window.matchMedia('(max-width: 767px)').matches
            ? 'mobile' : 'desktop'
        },
        isRequired(key) {
            for (var i in this.data.cards) {
                if (this.data.cards[i].cta_action == key) {
                    return i
                }
            }
            return false
        }
    }
});
