














import { useContent } from '~/composables';

import {
    // computed,
    // ref,
    // defineComponent,
    // useRouter,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

import {
    WntrTrustPilot
} from '~/modules/GraphQL/types';

export default {
    name: 'TrustPilotBanner',
    components: {
    },
    setup() {

        const { 
            getTrustPilotData
        } = useContent();

        var wntrTrustPilotRef = getTrustPilotData();

        return {
            wntrTrustPilotRef
        }

    },
    async fetch() {
        this.wntrTrustPilot = await this.wntrTrustPilotRef;
        this.overall_stars_rounded = this.wntrTrustPilot.overall_stars.split('.').join("")
    },
    data() {
        return {
            wntrTrustPilot: {},
            overall_stars_rounded: 0
        }
    },
    methods: {
        getRoundedScore(stars) {
            return  Math.round(stars).toFixed(1).split('.').join("")
        }
    }
}
