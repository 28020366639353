









































import { SfButton } from '@storefront-ui/vue'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
    name: 'TrustPilotFeatured',
    components: {
        SfButton,
        VueSlickCarousel
    },
    props: {
        content: {
            type: Array,
            default: {
                title: String,
                description: String,
                cutout: String,
                button: String,
                image: {
                    type: Object,
                    default() {
                        return {
                            alt: String,
                            src: String,
                            height: Number,
                            width: Number
                        }
                    }
                },
                url: {
                    type: Object,
                    default() {
                        return {
                            name: String,
                            params: {
                                type: Object,
                                default() {
                                    return {
                                        id: String
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
    },
    data() {
        return {
            settings: {
                arrows: true,
                dots: true,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 5000,
                cssEase: "ease-in-out",
                centerMode: false,
                centerPadding: '1px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: '40px'
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: true,
                            centerPadding: '40px',
                        }
                    }
                ]
            },
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    }
}
