var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"wide-container background-light-grey-10",attrs:{"id":"range-slider"}},[_c('div',{staticClass:"content-container py-5"},[_c('TitleDescription',{attrs:{"margin":"my-5","background":"background-light-grey-10","titleBorder":true,"data":_vm.data}}),_vm._v(" "),_c('div',[_c('b-tabs',{attrs:{"pills":"","content-class":"mt-3","align":"center"}},_vm._l((_vm.data.ranges),function(ref,index){
var title = ref.title;
var rangeItem = ref.rangeItem;
return _c('div',{key:(title + "-" + index)},[(rangeItem && rangeItem.length > 0)?[_c('b-tab',{attrs:{"title":title}},[_c('VueSlickCarousel',_vm._b({staticClass:"d-block d-lg-none my-4 neg-horizontal-margins",scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-left"})])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]}}],null,true)},'VueSlickCarousel',_vm.settings,false),[_vm._l((rangeItem),function(ref,cardIndex){
var image = ref.image;
var title = ref.title;
var url = ref.url;
var price = ref.price;
var sale_price = ref.sale_price;
var finishes = ref.finishes;
var selectedFinish = ref.selectedFinish;
var hoverImage = ref.hoverImage;
return [_c('div',{staticClass:"slick-item"},[_c('RangeSliderItem',{attrs:{"image":image,"title":title,"price":price,"sale_price":sale_price,"url":url,"finishes":finishes,"selectedFinish":selectedFinish,"hoverImage":hoverImage,"type":"small"}})],1)]})],2),_vm._v(" "),_c('VueSlickCarousel',_vm._b({staticClass:"d-none d-lg-block my-4 neg-horizontal-margins",scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-left"})])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]}}],null,true)},'VueSlickCarousel',_vm.settings,false),[_vm._l((_vm.rangeGroups(rangeItem)),function(group,i){return [_vm._l((rangeItem.slice(i * _vm.itemsPerChunk, (i + 1) * _vm.itemsPerChunk)),function(ref,cardIndex){
var image = ref.image;
var title = ref.title;
var url = ref.url;
var price = ref.price;
var sale_price = ref.sale_price;
var finish_count = ref.finish_count;
var finishes = ref.finishes;
var selectedFinish = ref.selectedFinish;
var hoverImage = ref.hoverImage;
return [(cardIndex == 0)?[_c('div',{staticClass:"slick-item"},[_c('RangeSliderItem',{attrs:{"image":image,"title":title,"price":price,"sale_price":sale_price,"url":url,"finish_count":finish_count,"finishes":finishes,"selectedFinish":selectedFinish,"hoverImage":hoverImage,"type":"large"}})],1)]:_vm._e()]}),_vm._v(" "),(rangeItem.slice(i * _vm.itemsPerChunk, (i + 1) * _vm.itemsPerChunk).length > 1)?[_c('div',{staticClass:"slick-item"},[_c('div',{staticClass:"grid custom-gap"},[_vm._l((rangeItem.slice(i * _vm.itemsPerChunk, (i + 1) * _vm.itemsPerChunk)),function(ref,cardIndex){
var image = ref.image;
var title = ref.title;
var url = ref.url;
var price = ref.price;
var sale_price = ref.sale_price;
var finish_count = ref.finish_count;
var finishes = ref.finishes;
var selectedFinish = ref.selectedFinish;
var hoverImage = ref.hoverImage;
return [(!(cardIndex == 0))?[_c('div',{staticClass:"g-col-6"},[_c('RangeSliderItem',{attrs:{"image":image,"title":title,"price":price,"sale_price":sale_price,"url":url,"finish_count":finish_count,"finishes":finishes,"selectedFinish":selectedFinish,"hoverImage":hoverImage,"type":"small"}})],1)]:_vm._e()]})],2)])]:_vm._e()]})],2)],1)]:_vm._e()],2)}),0)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }