var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section-cards wide-container"},[_c('div',{staticClass:"content-container"},[_c('TitleDescription',{attrs:{"margin":"my-5","titleBorder":false,"data":_vm.data}}),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.data.items),function(ref,index){
var image = ref.image;
var title = ref.title;
var cutout = ref.cutout;
var description = ref.description;
var button = ref.button;
var url = ref.url;
return _c('div',{key:(title + "-" + index),staticClass:"col-12 col-md-4 mb-5"},[_c('div',{staticClass:"card shadow light radius h-100"},[_c('div',{staticClass:"card-img",attrs:{"data-after":cutout}},[_c('img',{staticClass:"card-img-top",attrs:{"sizes":"(min-width: 1600px) 469px, (min-width: 992px) 33vw, (min-width: 576px) calc(100vw - 32px)10, 100vw","srcset":("\n                                " + _vm.image_prefix + "c_fill,w_320,h_" + (parseInt(320*_vm.getApectRatioHeight())) + "/f_auto,q_70/" + (image.src) + " 320w,\n                                " + _vm.image_prefix + "c_fill,w_480,h_" + (parseInt(480*_vm.getApectRatioHeight())) + "/f_auto,q_70/" + (image.src) + " 480w,\n                                " + _vm.image_prefix + "c_fill,w_640,h_" + (parseInt(640*_vm.getApectRatioHeight())) + "/f_auto,q_70/" + (image.src) + " 640w,\n                                " + _vm.image_prefix + "c_fill,w_768,h_" + (parseInt(768*_vm.getApectRatioHeight())) + "/f_auto,q_70/" + (image.src) + " 768w,\n                                " + _vm.image_prefix + "c_fill,w_896,h_" + (parseInt(896*_vm.getApectRatioHeight())) + "/f_auto,q_70/" + (image.src) + " 896w,\n                                " + _vm.image_prefix + "c_fill,w_1024,h_" + (parseInt(1024*_vm.getApectRatioHeight())) + "/f_auto,q_70/" + (image.src) + " 1024w\n                            "),"src":(_vm.image_prefix + "c_fill,w_469,h_" + (parseInt(469*_vm.getApectRatioHeight())) + "/f_auto,q_70/" + (image.src)),"alt":image.alt,"loading":"lazy","width":"469","height":("" + (parseInt(469*_vm.getApectRatioHeight())))}})]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(title))]),_vm._v(" "),_c('p',{staticClass:"card-text font-13"},[_vm._v(_vm._s(description))])]),_vm._v(" "),(url || button)?_c('div',{staticClass:"card-footer"},[_c('a',{attrs:{"href":("" + url)}},[_vm._v(_vm._s(button))])]):_vm._e()])])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }