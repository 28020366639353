
















































// import {
//     SfButton,
// } from '@storefront-ui/vue';
// import SvgImage from '~/components/General/SvgImage.vue';

import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    components: {
        // SfButton,
        // SvgImage,
    },
    methods: {
        changeVideoState: function() {
            const myvideo = this.$refs.video
            let videoPlayer = <HTMLVideoElement> document.getElementById('video_element')
            if ( this.videosStatePlaying ) {
                this.videosStatePlaying = false
                // myvideo.pause()
                videoPlayer.pause()
            } else {
                this.videosStatePlaying = true
                // myvideo.play()
                videoPlayer.play()
            }
        },
        updatePlaying: function () {
            this.videosStatePlaying = true
        },
        updatePaused: function() {
            this.videosStatePlaying = false
        }
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                    title: String,
                    description: String,
                    button: String,
                    url: String,
                    video: String,
                    videoPoster: String
                }
            }
        }
    },
    data() {
        return {
            videosStatePlaying: false,
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    }
});
