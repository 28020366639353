
































































































declare var bootstrap: any;

import VueSlickCarousel from 'vue-slick-carousel'
import type { PropType } from 'vue'
import type { RangeSliderCategory } from '~/modules/GraphQL/types';

import {
    SfButton,
    SfLink
} from '@storefront-ui/vue';

import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'RangeSlider',
    components: {
        VueSlickCarousel,
        SfLink,
        SfButton
    },
    setup() {
        onMounted(() => {
            
        })
    },
    props: {
        data: {
            type: Object,
            default () {
                return {
                    title: String,
                    description: String,
                    ranges: {
                        type: Array as PropType<RangeSliderCategory[]>,
                        required: true
                    }
                }
            }
        }
    },
    data() {
        return {
            settings: {
                lazyLoad: 'ondemand',
                dots: true,
                // dotsClass: "slick-dots custom-dot-class",
                // edgeFriction: 0.35,
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                speed: 750,
                autoplaySpeed: 5000,
                cssEase: "ease-in-out",
                // prevArrow: '<i class="fa-solid fa-chevron-left"></i>',
                // nextArrow: '<i class="fa-solid fa-chevron-right"></i>',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            },
            itemsPerChunk: 5,
        }
    },
    computed: {
    },
    methods: {
        rangeGroups: function (rangeItem) {
            if (rangeItem && Array.isArray(rangeItem) && typeof this.itemsPerChunk == 'number' ) {
                return Array.from(Array(Math.ceil(rangeItem.length / this.itemsPerChunk)).keys())
            }
        },
        onInitCarousel() {
            // console.log('our carousel is ready')
        },
    }
});
