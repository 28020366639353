//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    defineComponent,
} from '@nuxtjs/composition-api';

import VueSlickCarousel from 'vue-slick-carousel'

export default defineComponent ({
    name: 'SlickCarousel',
    components: {
        VueSlickCarousel,
        // SfLink,
        // SfButton
    },
    props: {
        content: {
            className: String,
            ctas: {
                type: Array,
                default() {
                    return {
                        title: String,
                        url: String
                    }
                }
            },
            desktopImage: {
                type: Object,
                default() {
                    return {
                        src: String,
                        width: Number,
                        height: Number,
                        alt: String
                    }
                }
            },
            mobileImage: {
                type: Object,
                default() {
                    return {
                        src: String,
                        width: Number,
                        height: Number,
                        alt: String
                    }
                }
            },
            title: String
        }
    },
    setup() {
        return {
            settings: {
                dots: true,
                // dotsClass: "slick-dots custom-dot-class",
                // edgeFriction: 0.35,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 6000,
                cssEase: "ease-in-out",
                // prevArrow: '<i class="fa-solid fa-chevron-left"></i>',
                // nextArrow: '<i class="fa-solid fa-chevron-right"></i>',
                // prevArrow: '',
                // nextArrow: '',
                centerMode: true,
                centerPadding: '0px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            autoplay: true
                        }
                    }
                ]
            },
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    methods: {
        onInitCarousel() {
            
        },
    },
});
