





























  import {
      defineComponent,
      ref,
  } from '@nuxtjs/composition-api';

  export default defineComponent({
      name: 'Video',
      components: {
          
      },
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      setup(props, context) {
          return {
              
          }
      },
      head() {
          return {
              
          }
      },
      methods: {
          changeVideoState: function() {
              const myvideo = this.$refs.video
              let videoPlayer = <HTMLVideoElement> document.getElementById('video_element')
              if ( this.videosStatePlaying ) {
                  this.videosStatePlaying = false
                  // myvideo.pause()
                  videoPlayer.pause()
              } else {
                  this.videosStatePlaying = true
                  // myvideo.play()
                  videoPlayer.play()
              }
          },
          updatePlaying: function () {
              this.videosStatePlaying = true
          },
          updatePaused: function() {
              this.videosStatePlaying = false
          }
      },
      data() {
          return {
              videosStatePlaying: false,
              image_prefix: ''
          }
      },
      props: {
          data: {
              type: Object,
              default() {
                  return {
                      poster: String,
                      src: String
                  }
              }
          }
      },
      created() {
          this.image_prefix = this.$config.baseImageUrl
      },
      watch: {
          
      }
});
